@import '../../index.scss';

.default-page {
    position: relative;
    width: 100%;
    height: 100%;
    font-family: 'stock';
    display: flex;
    align-items: center;
    justify-content: center;


    .content-box {
        position: absolute;
        color: white;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            font-size: $fontSize_title;
        }

        .buttons {
            display: flex;
            margin: 8rem;

            a {
                text-decoration: unset;
                font-size: 1.2rem;
                color: unset;
                position: relative;
                margin: 0 10px;

                &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    border: 0;
                    border-bottom: 2px;
                    padding-bottom: 5px;
                    border-style: dotted;

                    transition: padding 0.25s ease-out;
                }

                &:hover::after {
                    padding-bottom: 10px;
                }
            }


        }

        .icon-box {
            display: flex;
            margin-top: 10vh;

            .icon {
                margin: 10px;

                img {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    overflow: hidden;
                }
            }
        }
    }

    .tips {
        margin-top: 5vh;
        color: #333;
    }

}