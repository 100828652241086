.pin {
    position: absolute;
    z-index: 1;
    color: white;
    font-family: 'Stock';
}

.l {
    left: 20px;
}

.r {
    right: 20px;
}

.t {
    top: 20px;
}

.b {
    bottom: 20px;
}