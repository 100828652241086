.road {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: gray;
    width: 140px;

    .title {
        background-color: aquamarine;
    }

    .content {
        width: 300px;
        line-height: 15px;
        transform: scale(0.4);
        text-align: center;
        background-color: rebeccapurple;
    }
    
}