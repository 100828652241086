.project-box {


    .bg {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .mask {
            width: 90vw;
            height: 80vh;
            background-color: rgba($color: black, $alpha: .6);
            position: absolute;
            z-index: 999;
            border: solid 1px rgba($color: white, $alpha: .1);
            color: white;
            padding: 6rem;
            font-family: 'Stock';
            overflow-y: scroll;

            scrollbar-width: none;
            /* firefox */
            -ms-overflow-style: none;
            /* IE 10+ */
            overflow-x: hidden;
            overflow-y: auto;

            &::-webkit-scrollbar {
                display: none;
            }

            // display: flex;
            // flex-direction: column;
            // align-items: center;

            .indroduce {
                line-height: 5rem;
                text-align: center;
                font-size: 1.5rem;
            }

            .about-cut {
                margin-top: 10rem;
                text-align: center;

                .title {
                    font-size: 5rem;
                    margin-bottom: 3rem;
                }

                .content {
                    font-size: 1.5rem;
                    line-height: 5rem;
                }
            }

            .roadmap {
                margin-top: 10rem;
                text-align: center;

                .title {
                    font-size: 5rem;
                    margin-bottom: 5rem;
                }

                .content {
                    .map-item {
                        display: flex;
                        border: solid 1px white;
                        text-align: left;
                        margin-bottom: 5rem;


                        .index {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-right: 1px solid white;
                            flex: 0 0 20rem;
                            background-color: rgba($color: white, $alpha: .2);
                            color: white;
                            font-size: 4rem;
                        }

                        .content-item {
                            padding: 3rem;
                            line-height: 5rem;
                            flex-grow: 1;
                            flex-shrink: 0;
                            flex-basis: 0;
                        }
                    }
                }
            }

            .go-back {
                font-size: 3rem;
                text-align: center;
                margin: 5rem 0;
                display: inline-block;
                padding: 2rem;
                border: solid 1px white;
                transform: translateX(28vw);

                &:hover {
                    color: black;
                    background-color: white;
                }
            }

        }
    }
}