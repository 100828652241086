$fontSize_title: 6rem;
$fontSize_content: 1rem;

$fontColor: white;
$tipsColor: #333;


@font-face {
  font-family: 'stock';
  src: url('./asset/fonts/Block-Stock.ttf');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  background: black;
}