@import '../../index.scss';

.ctrl-x-page {
    width: 100%;
    height: 100%;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;

    .page-box {
        z-index: 1;
        color: $fontColor;
        font-family: 'Stock';
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        max-width: 2000px;

        .logo {
            width: 15rem;
            height: 15rem;
        }

        .title {
            font-size: $fontSize_title;
            margin-top: 3rem;
        }

        .content {
            font-size: $fontSize_content;
            line-height: 4rem;
            padding: 5rem 25rem;
        }

        .tips {
            color: $tipsColor;
            margin: 15rem 0 10rem 0;
            
        }

    }

}