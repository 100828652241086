@import "../../index.scss";

.ryoshi-page {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Stock';
    color: $fontColor;
    position: relative;
    overflow: hidden;

    .card {
        position: absolute;
        z-index: 1;

        .content {
            width: 40rem;
            min-width: 400px;
            height: 70rem;
            min-height: 700px;
            background-size: cover;

            .mask {
                width: 100%;
                height: 100%;
                background: rgba($color: #000000, $alpha: .8);
                backdrop-filter: blur(5px);
                padding: 5rem;
                text-align: center;
                line-height: 3rem;
                border: solid 1px rgba($color: #FFF, $alpha: .4);
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

    }
}